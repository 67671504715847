<template>
    <div class="is-name">
        <Navbar title="昵称" background="white" needBack>
            <div class="save" @click="save">保存</div>
        </Navbar>
        <van-cell-group class="name-value">
              <van-field v-model="name" label="职业" @input='changeName'  placeholder="请输入您的职业" />
              <van-icon name="clear" v-show='nameClear'  class="clear-name" color='#333333' @click="clearName"  size=".26rem"/>
              <van-icon name="clear" v-show="!nameClear"  class="clear-name" color='#aeaeae'  size=".26rem"/>
        </van-cell-group>
    </div>
</template>

<script>
export default {
      data(){
            return{
                name:'',
                nameClear:false
            }
        },
        mounted(){
          this.getUser()
          this.changeName()
        },
    methods:{

         getUser(){
             if(this.$route.query.job) this.name = this.$route.query.job
             
        },
         goBack() {
             this.$router.go(-1)
        },
        changeName(){
            if(this.name.length){
                this.nameClear = true
            }else{
                this.nameClear = false

            }
        },
        clearName(){
            this.name = ''
            this.nameClear = false

        },
        save(){
            let user = this.$route.query
            user.job = this.name
            this.$api.my.changeDetail(user).then(res=>{
                if(res.code === 200){
                    this.goBack()
                }
            })
        }
    }
}
</script>

<style lang='less' scoped>

.is-name {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  background: #F5F7FB;;
  .header {
    background-color: #ffffff;
    width: 100%;
    height: 0.88rem /* 44/50 */;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    z-index: 99999;
    position: fixed;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    .icon {
      position: fixed;
      left: 0.3rem /* 15/50 */;
      color: #7e7e7e;
      font-size: 0.36rem /* 18/50 */;
      width: 1em;
      height: 1em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
    .text {
      color: #333;
      font-size: 0.36rem /* 18/50 */;
      font-family: "PingFang SC";
      font-weight: 500;
    }
    .save {
      position: fixed;
      right: 0.3rem /* 15/50 */;
      font-size: 0.32rem /* 16/50 */;
      font-family: PingFang SC;
      font-weight: 400;
      color: #194cdd;
    }
  }
}
.name-value{
    position: relative;
    margin-top: .5rem;
    .clear-name{
        position: absolute;
        top: 50%;
        right: .3rem;
        transform: translateY(-50%);
    }
}

</style>